<template>
  <div class="pageContent">
    <!-- <noNavHeader :title="userCenterText" /> -->
    <div class="personalCenter">
      <el-row :gutter="15">
        <el-col :span="3">
          <el-col :span="24">
            <div class="personalCenterLeft">
              <div class="mainTitle">
                <p>{{ userCenterText }}</p>
              </div>
              <div>
                <p class="personalCenterTitle">个人中心</p>
              </div>
              <router-link to="/personalCenter/accountInfo">
                <p class="personalCenterItem">账户信息</p>
              </router-link>
              <router-link to="/personalCenter/accountSafe">
                <p class="personalCenterItem">账户安全</p>
              </router-link>
              <div>
                <p class="personalCenterTitle">企业信息</p>
              </div>
              <router-link to="/personalCenter/electronicSignature">
                <p class="personalCenterItem">电子签章管理</p>
              </router-link>
              <router-link v-if="isShowIdentification === true" to="/personalCenter/enterprise">
                <p class="personalCenterItem">企业认证</p>
              </router-link>
              <router-link v-if="isShowDian === true"  to="/personalCenter/personalProductInfo">
                  <p class="personalCenterItem">经营产品信息</p>
                </router-link>
              <router-link
                v-if="isShowIdentification === false"
                to="/personalCenter/enterpriseAuth"
              >
                <p class="personalCenterItem">公司认证</p>
              </router-link>
              <div v-if="isShowStaff === true">
                <div>
                  <p class="personalCenterTitle">员工管理</p>
                </div>
                <router-link to="/personalCenter/staffAccountMgt">
                  <p class="personalCenterItem">员工账号管理</p>
                </router-link>
               
                <!-- <router-link to="/personalCenter/staffRoleMgt">
                  <p class="personalCenterItem">角色管理</p>
                </router-link>-->
              </div>
               
              <div  v-if="isShowDian === true">
                <p class="personalCenterTitle">店铺管理</p>
              </div>
              <router-link to="/personalCenter/shopManage"  v-if="isShowDian === true">
                <p class="personalCenterItem">我的店铺</p>
              </router-link>
            </div>
          </el-col>
        </el-col>
        <el-col :span="21">
          <div class="personalCenterRight">
            <router-view @reLoad="getIdentificationStatus()" />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import noNavHeader from "../base/header/noNavHeader";

export default {
  data() {
    return {
      passwordStatus: null,
      isShowIdentification: null,
      isShowStaff: null,
      isShowDian:false,
      userCenterText: window.common.userCenterText,
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"]),
  },
  components: {
    noNavHeader,
  },
  mounted() {
    this.getIsSetPassword();
    this.getIdentificationStatus();
  },
  methods: {
    change(item) {
      if (item == "accountInfo") {
        this.title = "账户信息";
      } else if (item == "accountSafe") {
        this.title = "账户安全";
      } else if (item == "enterprise") {
        this.$router.push("/personalCenter/enterprise");
      }
    },
    getIsSetPassword() {
      protocolFwd.param_queryPwdIsSetup.param.loginAccount = window.sessionStorage.getItem(
        "sessionTel"
      );
      http.postFront(protocolFwd.param_queryPwdIsSetup).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value) {
            this.passwordStatus = 1;
          } else {
            this.passwordStatus = 2;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getIdentificationStatus() {
      http
        .postFront(protocolFwd.param_enterpriseCertification)
        .then((response) => {
          const { code, message, value } = response.data;
          if (code == 0) {
            if (Object.keys(value).length !== 0) {
            
              if (this.sessionInfoGetter.firmId == value.firm_id) {
                // 说明返回的是本人信息
                // if (
                //   value.status == 1 ||
                //   value.status == 2 ||
                //   value.status == 4
                // ) {
                  this.isShowIdentification = true;
                // } else {
                //   this.isShowIdentification = false;
                // }
                if (value.status == 2) {
                  this.isShowDian = true;
                  this.isShowStaff = true;
                } else {
                  this.isShowStaff = false;
                  this.isShowDian = false;
                }
              } else if (this.sessionInfoGetter.firmId != value.firm_id) {
                // 说明返回的是父级信息
                this.isShowIdentification = true;
                this.isShowStaff = false;
                 if (value.status == 2) {
                  this.isShowDian = true;
                } else {
                  this.isShowDian = false;
                }
                
              }
            } else {
              this.isShowIdentification = false;
              this.isShowStaff = false;
              this.isShowDian = false;
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.personalCenter {
  width: 80%;
  min-width: 1300px;
  min-height: 1000px;
  margin: 0 auto;
  // background-color: #ffffff;
  // border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.personalCenter .personalCenterLeft {
  line-height: 40px;
  background-color: #ffffff;
  // border: 1px solid #dcdcdc;
}
.personalCenter .personalCenterLeft .mainTitle {
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  background-color: $commonThemeColor;
}
.personalCenter .personalCenterLeft .mainTitle p {
  font-size: 16px;
}
.personalCenter .personalCenterLeft .personalCenterTitle {
  padding-left: 30px;
  font-size: 16px;
  font-weight: 700;
}
.personalCenter .personalCenterLeft .personalCenterItem {
  padding-left: 30px;
  font-size: 14px;
  color: #333333;
}
.personalCenter .personalCenterRight {
  padding: 15px 0;
  min-height: 1000px;
  background-color: #ffffff;
  // border: 1px solid #dcdcdc;
}
.router-link-active p.personalCenterItem {
  color: $commonThemeColor !important;
}
</style>
