<template>
  <div class="capitalHeader">
    <div class="capitalHeaderBox">
      <el-row>
        <el-col :span="5">
          <div class="logo" @click="goIndex()">
            <img :src="logoUrlGetter" />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    title: {
      type: String,
      default: function() {
        return "";
      }
    }
  },
  computed: {
    ...mapGetters(["logoUrlGetter"])
  },
  methods: {
    goIndex() {
      this.$router.push("/index");
    }
  }
};
</script>

<style lang="scss" scoped>
.capitalHeader {
  width: 100%;
}
.capitalHeaderBox {
  width: 80%;
  min-width: 1300px;
  margin: 10px auto;
}
.capitalHeaderBox .logo {
  display: flex;
  align-items: center;
  height: 70px;
}
.capitalHeaderBox .logo img {
  display: block;
  width: 176px;
  height: 70px;
  cursor: pointer;
}
.capitalHeaderBox .title {
  margin: 20px 0;
  padding-left: 15px;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
}
</style>
